import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { MatDialogRef, MatDialogTitle, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { CdkScrollable } from '@angular/cdk/scrolling';

import { AppConfigService } from '@scriptac/common/core/services/app-config.service';

/** Privacy policy dialog component. */
@Component({
	selector: 'scriptaw-privacy-policy-dialog',
	templateUrl: './privacy-policy-dialog.component.html',
	styleUrls: ['./privacy-policy-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		MatDialogTitle,
		MatIconButton,
		MatDialogClose,
		MatIcon,
		CdkScrollable,
		MatDialogContent,
	],
})
export class PrivacyPolicyDialogComponent {
	private readonly dialogRef = inject<MatDialogRef<PrivacyPolicyDialogComponent>>(MatDialogRef);

	/** Scripta URL. */
	public readonly scriptaUrl = inject(AppConfigService).scriptaUrl;

	/** Closes dialog. */
	public close(): void {
		this.dialogRef.close();
	}
}
